@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap);
.compelling-container {
  height: 600px;
  background-color: #fff;
  display: flex;
  padding-top: 125px;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 780px) {
  .compelling-container {
    height: auto;
    padding: 50px 20px 50px 20px;
  }
}

.compelling-container .offer-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 25px auto;
  max-width: 1280px;
  text-align: center;
}

.compelling-container .offer-title h1 {
  font-size: 25px;
  text-transform: uppercase;
}

.compelling-container .offer-title h2 {
  margin: 10px;
  color: #ffb834;
}

.compelling-container .rent-out {
  -ms-grid-row-align: center;
  align-self: center;
}

@media (max-width: 1000px) {
  .compelling-container .rent-out .rent-out-button {
    width: 100%;
  }
}

.compelling-container .offer-container {
  max-width: 1280px;
  margin: 30px auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

@media only screen and (max-width: 780px) {
  .compelling-container .offer-container {
    flex-direction: column;
  }
}

.compelling-container .offer-container .offer {
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  margin-bottom: 150px;
}

@media (max-width: 1000px) {
  .compelling-container .offer-container .offer {
    padding: 10px 10px;
  }
}

@media (max-width: 600px) {
  .compelling-container .offer-container .offer {
    margin-bottom: 50px;
  }
}

.compelling-container .offer-container .offer img {
  height: 120px;
}

@media only screen and (max-width: 780px) {
  .compelling-container .offer-container .offer img {
    height: 120px;
  }
}

.compelling-container .offer-container .offer h3 {
  font-size: 30px;
  color: #333;
  margin-bottom: 25px;
}

.compelling-container .offer-container .offer p {
  text-align: center;
  font-size: 17px;
  padding: 0 50px;
}

#footer {
  background-color: #333;
  padding-top: 50px;
  width: 100%;
  padding-bottom: 50px;
}

#footer .footer-header {
  text-align: center;
  margin: 0 0 20px 0;
}

#footer .sub-hr {
  border: none;
  height: 1px;
  background-color: rgba(223, 183, 130, 0.124);
  text-align: center;
  margin: 10px 5%;
}

@media (min-width: 599px) {
  #footer .sub-hr {
    display: none;
  }
}

#footer .sub-header {
  text-align: center;
  padding-bottom: 30px;
}

#footer .sub-header h3 {
  color: #ffd09c;
}

#footer .sub-header .footer-text {
  color: #ffd09c;
  text-decoration: none;
  font-size: 18px;
  margin: 0 100px 0 100px;
  padding: 0 20px 15px 20px;
}

@media (max-width: 1000px) {
  #footer .sub-header .footer-text {
    margin: 0 20px 0 20px;
  }
}

@media (max-width: 600px) {
  #footer .sub-header .footer-text {
    margin: 0 50px 0 50px;
  }
}

#footer .sub-header .footer-link a,
#footer .sub-header a:hover,
#footer .sub-header a:focus,
#footer .sub-header a:active {
  text-decoration: none;
  color: #f7f5f5;
  font-size: 18px;
}

#footer .sub-header .custom-button, #footer .sub-header .compelling-container .rent-out .rent-out-button, .compelling-container .rent-out #footer .sub-header .rent-out-button {
  background-color: #ffd09c;
  color: #333;
  margin: 15px;
}

#footer .sub-header .custom-button:hover, #footer .sub-header .compelling-container .rent-out .rent-out-button:hover, .compelling-container .rent-out #footer .sub-header .rent-out-button:hover {
  opacity: 0.7;
  transform: none;
}

#footer .sub-header .custom-button a, #footer .sub-header .compelling-container .rent-out .rent-out-button a, .compelling-container .rent-out #footer .sub-header .rent-out-button a {
  color: #333;
}

#footer .sub-header .custom-button:nth-last-child(1), #footer .sub-header .compelling-container .rent-out .rent-out-button:nth-last-child(1), .compelling-container .rent-out #footer .sub-header .rent-out-button:nth-last-child(1) {
  border: 2px solid #ffd09c;
  color: #ffd09c;
}

#footer .sub-header .icon, #footer .sub-header .answer-container .done-btn .icon-filled, .answer-container .done-btn #footer .sub-header .icon-filled, #footer .sub-header .answer-container .done-btn-filled .icon-filled, .answer-container .done-btn-filled #footer .sub-header .icon-filled {
  color: #ffd09c !important;
  margin: 10px;
}

#footer .sub-header .icon:hover, #footer .sub-header .answer-container .done-btn .icon-filled:hover, .answer-container .done-btn #footer .sub-header .icon-filled:hover, #footer .sub-header .answer-container .done-btn-filled .icon-filled:hover, .answer-container .done-btn-filled #footer .sub-header .icon-filled:hover {
  opacity: 0.7;
  transform: none;
}

#footer .logo {
  height: 120px;
  color: #ffd09c;
  padding-bottom: 40px;
}

.page-header, .page-header-landing {
  max-width: 1280px;
  padding: 50px;
  display: flex;
  height: 150px;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

@media only screen and (max-width: 780px) {
  .page-header, .page-header-landing {
    margin: 0 20px;
    padding: 15px 0;
  }
}

.page-header button, .page-header-landing button {
  margin-right: 20px;
}

.page-header .menu-wrapper, .page-header-landing .menu-wrapper {
  display: flex;
  align-items: center;
}

.page-header .menu-wrapper .MuiSelect-select, .page-header-landing .menu-wrapper .MuiSelect-select {
  color: #333;
}

.page-header .MuiInputBase-input, .page-header-landing .MuiInputBase-input {
  color: #333;
}

.page-header .MuiSelect-icon, .page-header-landing .MuiSelect-icon {
  color: #333 !important;
}

.page-header .dropdown-menu button:first-of-type, .page-header-landing .dropdown-menu button:first-of-type {
  color: #61f7d2;
}

.page-header .logo, .page-header-landing .logo {
  height: 100px;
  margin-top: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 780px) {
  .page-header .logo, .page-header-landing .logo {
    margin-top: 25px;
    height: 75px;
  }
}

.page-header-landing {
  border-bottom: none;
}

.page-header-landing .menu-wrapper .MuiSelect-select {
  color: white;
  font-size: 18px;
  padding-bottom: 0;
}

@media only screen and (max-width: 780px) {
  .page-header-landing .MuiSelect-select {
    color: white;
    font-size: 18px;
    padding-bottom: 0;
  }
}

.page-header-landing .dropdown-menu button:first-of-type {
  color: white;
}

.page-header-landing .MuiSelect-icon {
  color: white !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#menu-language .MuiPaper-root {
  background-color: #61f7d2;
  color: '#222';
  width: 125px;
}

.hero-section {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url(/static/media/heroImgCompressed.8fc9ab0d.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 820px;
}

@media only screen and (max-width: 780px) {
  .hero-section {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}

.hero-section .welcome-text {
  display: flex;
  flex-direction: column;
  color: white;
  margin: 0 auto;
  padding: 20px 50px;
  text-align: left;
  max-width: 900px;
}

@media only screen and (max-width: 780px) {
  .hero-section .welcome-text {
    padding: 0;
    flex-direction: column;
    margin: 0;
    align-items: flex-start;
  }
}

.hero-section .welcome-text #wizard-container {
  width: 100%;
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.37);
  margin: 10px 15px;
  padding: 4px 10px 12px 10px;
  border-radius: 5px;
  max-height: 240px;
  overflow-y: scroll;
  transition: 1s;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.hero-section .welcome-text #wizard-container::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.hero-section .welcome-text #wizard-container:focus-within {
  max-height: 405px;
}

.hero-section .welcome-text #wizard-container:focus-visible {
  outline: none;
}

@media only screen and (max-width: 780px) {
  .hero-section .welcome-text #wizard-container {
    max-height: 260px;
    padding: 10px 10px;
    margin: 0 0 40px 0;
    border-radius: 0;
  }
  .hero-section .welcome-text #wizard-container:focus-within {
    max-height: 250px;
  }
}

.hero-section .welcome-text #wizard-container .submit-container {
  display: flex;
  justify-content: center;
  margin: 0 0;
}

.hero-section .welcome-text h1 {
  padding: 30px 0 50px 10px;
  line-height: 1.2;
  font-size: 55px;
  max-width: 800px;
}

@media only screen and (max-width: 720px) {
  .hero-section .welcome-text h1 {
    font-size: 46px;
  }
}

@media only screen and (max-width: 780px) {
  .hero-section .welcome-text h1 {
    text-align: center;
    margin: 75px 0;
    font-size: 38px;
    padding: 20px;
  }
}

.hero-section .submit-message-container {
  width: 75%;
}

.welcomeMessage {
  color: #333;
  padding-bottom: 30px;
  border-bottom: 3px solid #ffd09c;
}

@media only screen and (max-width: 780px) {
  .welcomeMessage {
    margin: 20px 20px 50px 20px;
  }
}

.question-container {
  margin: 40px 50px 30px 0;
  display: flex;
  padding: 0 20px;
}

@media only screen and (max-width: 780px) {
  .question-container {
    justify-content: left;
    margin: 40px 0 20px 0;
    padding: 0;
  }
}

.question-container.hero {
  margin-bottom: 10px;
}

.question-container img {
  height: 60px;
  -ms-grid-row-align: center;
  align-self: center;
  margin-right: 10px;
  border-radius: 100%;
}

.question-container p {
  color: #333;
  background-color: #61f7d2;
  font-size: 1.4rem;
  padding: 16px 32px;
  border-radius: 10px;
}

@media only screen and (max-width: 780px) {
  .question-container p {
    font-size: 18px;
    padding: 20px;
  }
}

.answer-container {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 780px) {
  .answer-container {
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
  }
}

.answer-container .answer-form-container {
  width: auto;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 780px) {
  .answer-container .answer-form-container {
    align-items: flex-end;
    padding: 0;
  }
}

.answer-container h3 {
  font-size: 24px;
  color: #ffd09c;
  font-weight: 500;
  padding-right: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
  .answer-container h3 {
    font-size: 18px;
    padding-right: 65px;
  }
}

.answer-container .input, .answer-container .input:disabled, .answer-container .input-filled {
  font-size: 1.4rem;
  border-radius: 10px;
  padding: 0 10px;
  text-align: center;
  height: 55px;
  margin-right: 10px;
  outline: none;
  border: 2px solid #ffd09c;
  color: #ffd09c;
  background-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 780px) {
  .answer-container .input, .answer-container .input:disabled, .answer-container .input-filled {
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.answer-container .input[type='number'], .answer-container .input-filled[type='number'] {
  font-size: 1.7rem;
}

.answer-container .input::before, .answer-container .input:disabled::before, .answer-container .input-filled::before {
  outline: none;
  border: none;
}

.answer-container .input:focus::-webkit-input-placeholder, .answer-container .input-filled:focus::-webkit-input-placeholder {
  color: transparent;
}

.answer-container .input:focus:-ms-input-placeholder, .answer-container .input-filled:focus:-ms-input-placeholder {
  color: transparent;
}

.answer-container .input:focus::placeholder, .answer-container .input-filled:focus::placeholder {
  color: transparent;
}

.answer-container .input::-webkit-input-placeholder, .answer-container .input:disabled::-webkit-input-placeholder, .answer-container .input-filled::-webkit-input-placeholder {
  color: #ffd09c;
}

.answer-container .input:-ms-input-placeholder, .answer-container .input:disabled:-ms-input-placeholder, .answer-container .input-filled:-ms-input-placeholder {
  color: #ffd09c;
}

.answer-container .input::placeholder, .answer-container .input:disabled::placeholder, .answer-container .input-filled::placeholder {
  color: #ffd09c;
}

.answer-container .input[type='number'], .answer-container .input-filled[type='number'] {
  width: 100px;
}

.answer-container .input:disabled, .answer-container .input-filled:disabled {
  background-color: #ffd09c;
  color: #333;
}

.answer-container .input:disabled:hover, .answer-container .input-filled:disabled:hover {
  background-color: #ffd09c;
  transform: none;
  transition: none;
  color: #333;
}

.answer-container .input:hover, .answer-container .input-filled:hover {
  background-color: rgba(255, 208, 156, 0.1);
  transform: translateY(-3px);
  transition: all 0.3s;
  color: #ffd09c;
}

.answer-container .input-filled {
  background-color: #ffd09c;
  color: #333;
}

.answer-container .done-btn, .answer-container .done-btn-filled {
  display: flex;
  align-self: center;
  border-radius: 100%;
  margin-bottom: 10px;
  height: 55px;
  width: 55px;
  padding: 10px;
  cursor: pointer;
  border: 2px solid #ffd09c;
  transition: 0.3s;
}

@media only screen and (max-width: 780px) {
  .answer-container .done-btn, .answer-container .done-btn-filled {
    height: 45px;
    width: 45px;
    margin-top: 10px;
    margin-bottom: 7px;
  }
}

.answer-container .done-btn:hover, .answer-container .done-btn-filled:hover {
  background-color: #ffd09c;
}

.answer-container .done-btn:hover .icon, .answer-container .done-btn-filled:hover .icon, .answer-container .done-btn:hover .icon-filled, .answer-container .done-btn-filled:hover .icon-filled {
  color: #333;
}

.answer-container .done-btn-filled {
  background-color: #ffd09c;
}

.answer-container .done-btn .icon, .answer-container .done-btn-filled .icon, .answer-container .done-btn .icon-filled, .answer-container .done-btn-filled .icon-filled {
  color: #ffd09c;
}

.answer-container .done-btn .icon-filled, .answer-container .done-btn-filled .icon-filled {
  color: #333;
}

.answer-container .radio-group {
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.answer-container .radio-group input[type='radio'] {
  visibility: hidden;
  display: none;
}

.answer-container .radio-group label {
  display: inline-block;
  color: #333;
  min-width: 45px;
  text-align: center;
  font-size: 1.4rem;
  color: #ffd09c;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 13px;
  border: 2px solid #ffd09c;
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 780px) {
  .answer-container .radio-group label {
    padding: 8px;
    font-size: 18px;
    margin: 6px 10px 6px 0px;
  }
}

.answer-container .radio-group .radio:hover {
  background-color: rgba(255, 208, 156, 0.1);
  transform: translateY(-5px);
  transition: 300ms;
}

.answer-container .radio-group input[type='radio']:checked + label {
  color: #333;
  background: #ffd09c;
}

.answer-container .radio-group label + input[type='radio'] + label {
  border-left: 2px solid #ffd09c;
}

.location-question-container .answer-form-container {
  display: flex;
}

.location-question-container .answer-form-container .rrs__multiselect-label__badge {
  margin-left: 15px;
  font-size: 14px;
  color: #ffd09c;
  border-color: #ffd09c;
}

.location-question-container .answer-form-container .location-filled .rrs__button {
  background-color: #ffd09c;
  color: #333;
}

.location-question-container .answer-form-container .location-filled .rrs__button .rrs__multiselect-label__badge {
  color: #333;
  border: 2px solid;
}

.location-question-container .answer-form-container .location-filled svg {
  fill: #333;
}

.location-question-container .answer-form-container .rrs {
  font-size: 1.4rem;
  width: 300px;
  margin-right: 10px;
  color: #ffd09c;
}

.location-question-container .answer-form-container .rrs .rrs__button + .rrs__options {
  border: none;
  background-color: #333;
  top: auto;
  bottom: 100%;
}

@media only screen and (max-width: 780px) {
  .location-question-container .answer-form-container .rrs .rrs__button + .rrs__options {
    top: 60px;
    bottom: auto;
  }
}

.location-question-container .answer-form-container .rrs .rrs__option.rrs__option--next-selection {
  background-color: #444 !important;
  outline: none;
}

.location-question-container .answer-form-container .rrs__button {
  background-color: transparent;
  color: #ffd09c;
  border-radius: 10px;
  outline: none;
  border: 2px solid #ffd09c;
  box-shadow: none;
  height: 55px;
}

.location-question-container .answer-form-container .rrs__button svg {
  fill: #ffd09c;
}

.location-question-container .answer-form-container .rrs--has-changed .rrs__label {
  color: #ffd09c;
}

.location-question-container .answer-form-container .rrs__option:hover {
  color: orange;
  background-color: #444;
}

.location-question-container .answer-form-container .rrs__option.rrs__option--next-selection {
  color: #ffd09c;
}

.location-question-container .answer-form-container .rrs__option.rrs__option--selected {
  color: #61f7d2;
}

.location-question-container .answer-form-container .rrs__option.rrs__option--selected .checkbox {
  border-color: #61f7d2;
}

.location-question-container .answer-form-container .rrs__option.rrs__option--selected svg {
  fill: #61f7d2;
}

input.phone {
  width: 250px !important;
}

.MuiCircularProgress-svg {
  color: #fff;
}

.testimonial-section {
  background-color: whitesmoke;
}

.illu-container {
  padding: 75px 25px 0 25px;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.illu-container .illustration {
  width: 150px;
}

.illu-container .testimonial-subtitle {
  max-width: 1280px;
  font-size: 16px;
  text-align: start;
  margin-bottom: 25px;
}

@media only screen and (max-width: 780px) {
  .illu-container .testimonial-subtitle {
    font-size: 12px;
  }
}

.testimonials-header {
  font-size: 18px;
  color: #333;
  margin: 50px auto 50px auto;
  max-width: 1050px;
}

@media (max-width: 1000px) {
  .testimonials-header {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 780px) {
  .testimonials-header {
    font-size: 14px;
    padding: 0 20px;
  }
}

.testimonials-container {
  display: flex;
  width: 100%;
  height: 750px;
  margin: 0 0 150px 0;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 780px) {
  .testimonials-container {
    margin-bottom: 0;
    padding-top: 25px;
  }
}

.testimonials-container .bg-1 {
  background-image: url(/static/media/social-bg-1.df30682c.jpg);
}

.testimonials-container .bg-2 {
  background-image: url(/static/media/social-bg-2.21be6cbd.png);
}

.testimonials-container .slider-container {
  position: absolute;
  max-width: 1024px;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 750px;
  background-size: cover;
  background-position: center;
  align-items: center;
}

.testimonials-container .testimonial-container {
  margin: 50px;
  height: 80%;
  border-radius: 4px;
  width: 400px;
  background-color: white;
  border-radius: 4px;
  text-align: center;
}

@media only screen and (max-width: 780px) {
  .testimonials-container .testimonial-container {
    margin: 50px 15px;
  }
}

.testimonials-container .testimonial-container .social-image {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 40%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.testimonials-container .testimonial-container .person-1 {
  background-image: url(/static/media/leif.b796e8b4.jpg);
}

.testimonials-container .testimonial-container .person-2 {
  background-image: url(/static/media/matilda.e4e5b0ed.jpg);
}

.testimonials-container .testimonial-container .info-container {
  display: flex;
  justify-content: space-between;
  height: 60%;
  flex-direction: column;
}

.testimonials-container .testimonial-container .info-container .testimonial-text {
  height: 50%;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: whitesmoke;
}

.testimonials-container .testimonial-container .info-container .MuiIconButton-root {
  background-color: #ffd09c;
  color: white;
  transition: all 0.2s;
}

.testimonials-container .testimonial-container .info-container .MuiIconButton-root:hover {
  transform: scale(1.2);
}

.testimonials-container .testimonial-container .info-container .MuiIconButton-root:active {
  transform: scale(0.8);
}

.testimonials-container .testimonial-container .info-container h4 {
  margin: 10px;
  font-size: 18px;
  color: #666;
  font-style: italic;
}

.testimonials-container .testimonial-container .info-container .testimonial-person {
  margin-top: 10px;
}

.testimonials-container .testimonial-container .info-container .name {
  font-size: 12px;
  text-transform: uppercase;
  color: #333;
  font-weight: bold;
}

.testimonials-container .testimonial-container .info-container .job-title {
  font-size: 12px;
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
}

.testimonials-container .testimonial-container .info-container .logo-section {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
}

.testimonials-container .testimonial-container .info-container .logo-section button {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.testimonials-container .testimonial-container .info-container .logo-section .company-logo {
  max-width: 50%;
  max-height: 80px;
}

* {
  margin-top: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  height: 100%;
  align-items: center;
  min-height: 100vh;
}

section {
  margin-top: 50px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  overflow: hidden;
}

.testimonialBox, .testimonialBox-employee {
  border: 1px solid #f0e4e4;
}

.swiper-wrapper {
  margin-top: 20px;
  width: 100%;
}

.pagination {
  margin-bottom: 70px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  align-items: center;
  height: 100%;
  text-align: center;
  background-color: rgba(250, 245, 245, 0.541);
}

.testimonialBox, .testimonialBox-employee {
  background: #ddd;
  border: 5px solid #ddd;
  border-radius: 5px;
}

.testimonialBox h3, .testimonialBox-employee h3 {
  color: #333;
}

.testimonialBox-employee {
  background: #ffd09c;
  border: 5px solid #ffd09c;
  border-radius: 5px;
}

.swiper-container {
  height: 100%;
}

.swiper-container p {
  font-size: 20px;
  color: #333;
  margin: 0 50px;
}

.swiper-container h3 {
  margin-top: 10px;
  font-size: 25px;
}

.swiper-container h4 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

.swiper-container .content {
  margin-bottom: 30px;
}

:root {
  --swiper-theme-color: #b4c4da !important;
}

.profile-image {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 40%;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: none !important;
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

@media only screen and (max-width: 1280px) {
  .swiper-container {
    width: 100%;
  }
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  transform: scale(1.1);
}

.rent-out-page {
  height: 100vh;
}

.rent-out-page .form-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
  margin-top: 50px;
  padding: 0 50px;
  border-radius: 10px;
  border: 1px solid #ffd09c;
}

@media only screen and (max-width: 780px) {
  .rent-out-page .form-container {
    border: none;
    margin-top: 0;
    padding: 0 20px 0 20px;
  }
}

.rent-out-page .form-container .MuiFormLabel-root.Mui-focused {
  color: #333;
}

.rent-out-page .form-container .PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline {
  border-color: #ffd09c;
}

.rent-out-page .form-container .close-form-button {
  align-self: flex-end;
  margin-top: 10px;
  padding: 10px;
}

.rent-out-page .form-container .close-form-button a {
  height: 24px;
}

.rent-out-page .form-container .custom-button, .rent-out-page .form-container .compelling-container .rent-out .rent-out-button, .compelling-container .rent-out .rent-out-page .form-container .rent-out-button {
  width: 200px;
  margin: 20px auto;
}

.rent-out-page .form-container form {
  display: flex;
  flex-direction: column;
}

#root {
  background-color: whitesmoke;
  font-size: 62.5%;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif !important;
}

.success-snackbar .MuiSnackbarContent-root {
  background-color: lime;
  color: white;
  font-size: 22px;
  padding: 0 20px;
}

h2 {
  font-size: 32px;
  color: #ffd09c;
  margin: 50px 0;
}

@media only screen and (max-width: 700px) {
  h2 {
    font-size: 1.2rem;
    margin: 50px;
  }
}

h3 {
  font-size: 24px;
  color: #ffd09c;
  padding-right: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
  h3 {
    font-size: 18px;
    margin-top: 5px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 520px) {
  h3 {
    font-size: 18px;
    padding-right: 10px;
  }
}

.custom-button, .compelling-container .rent-out .rent-out-button {
  height: 50px;
  cursor: pointer;
  background-color: rgba(97, 247, 210, 0.9);
  color: #333;
  border-radius: 4px;
  border: none;
  padding: 14px 24px;
  font-size: 18px;
  text-decoration: none;
  transition: 0.3s;
}

.custom-button:hover, .compelling-container .rent-out .rent-out-button:hover {
  background-color: #61f7d2;
  transform: translateY(-5px);
}

.custom-button a, .compelling-container .rent-out .rent-out-button a {
  font-size: 18px;
  text-decoration: none;
  color: #222;
}

.MuiInput-root {
  color: white !important;
}

.MuiToolbar-root {
  justify-content: flex-end;
}

.MuiButton-root {
  border-radius: 20px !important;
  font-size: 20px !important;
  color: white !important;
  background-color: #61f7d2 !important;
  padding: 10px !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

@media only screen and (max-width: 520px) {
  .MuiButton-root {
    font-size: 18px !important;
  }
}

.loading-screen {
  position: absolute;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-screen .loading-screen-logo {
  height: 150px;
  margin-bottom: 25px;
}

.loading-screen h1 {
  margin-bottom: 15px;
}

.loading-screen .MuiCircularProgress-svg {
  color: #61f7d2;
}

